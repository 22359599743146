/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'minecart-loaded': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 15a1 1 0 110-2 1 1 0 010 2m0 1a2 2 0 100-4 2 2 0 000 4m8-1a1 1 0 110-2 1 1 0 010 2m0 1a2 2 0 100-4 2 2 0 000 4M.115 3.18A.5.5 0 01.5 3h15a.5.5 0 01.491.592l-1.5 8A.5.5 0 0114 12H2a.5.5 0 01-.491-.408l-1.5-8a.5.5 0 01.106-.411zm.987.82l1.313 7h11.17l1.313-7z"/><path pid="1" fill-rule="evenodd" d="M6 1a2.498 2.498 0 014 0c.818 0 1.545.394 2 1 .67 0 1.552.57 2 1h-2c-.314 0-.611-.15-.8-.4-.274-.365-.71-.6-1.2-.6-.314 0-.611-.15-.8-.4a1.497 1.497 0 00-2.4 0c-.189.25-.486.4-.8.4-.507 0-.955.251-1.228.638q-.136.194-.308.362H3c.13-.147.401-.432.562-.545a1.6 1.6 0 00.393-.393A2.5 2.5 0 016 1"/>',
    },
});
